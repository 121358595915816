<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0">
        <v-icon class="cursor--pointer" @click="backreport">mdi-reply</v-icon>

        {{ "User Sales Details" }}
      </div>
    </div>
    <v-card :loading="loadingData">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">{{ `${title}:  ${getEmployeeName}` }}</h3>
        </div>
        <v-spacer></v-spacer>

        <pdf-reports
          :dataTable="data"
          :employee="getEmployeeName"
          :title="title"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.paymentDate`]="{ item }">
            {{ prettyDate(item.createAt) }}
          </template>
          <template v-slot:[`item.patient_name`]="{ item }">
            {{ item.patient_name ? item.patient_name : "-" }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.payment.details ? item.payment.details : "-" }}
          </template>
          <template v-slot:[`item.descriptionf`]="{ item }">
            {{ item.payment.details ? item.payment.details : "-" }}
          </template>
          <template v-slot:[`item.sales`]="{ item }">
            {{ getSales(item) }}
          </template>
          <template v-slot:[`item.salesf`]="{ item }">
            {{ getSalesf(item) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div>
              {{ item.payment.amount | currency }}
            </div>
          </template>
          <template v-slot:[`item.amountf`]="{ item }">
            {{ getAmount(item) | currency }}
          </template>
          <template v-slot:[`item.typef`]="{ item }">
            {{ typesShow(getType(item)) }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ typesShow(getType(item)) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogPdf"
      persistent
      :overlay="false"
      max-width="820px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          PDF <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                large
                icon
                @click="generateReport"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" large icon @click="cancelGenerate"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </v-card-title>
        <div
          id="pdf"
          :style="{
            width: '800px !important',
            padding: '10px 10px 10px 5px !important',
            'text-align': 'center',
          }"
        >
          <v-divider></v-divider>
          <h3>{{ `${title}:  ${getEmployeeName}` }}</h3>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Type</th>
                  <th class="text-left">Sales</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Patient name</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Payment date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.name">
                  <td class="text-left">{{ typesShow(getType(item)) }}</td>
                  <td class="text-left">{{ getSales(item) }}</td>
                  <td class="text-left">
                    {{ item.payment.details ? item.payment.details : "-" }}
                  </td>
                  <td class="text-left">
                    {{ item.patient_name ? item.patient_name : "-" }}
                  </td>
                  <td class="text-left">
                    {{ item.payment.amount | currency }}
                  </td>
                  <td class="text-left">{{ prettyDate(item.createAt) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { mapGetters } from "vuex";
import PdfReports from "@/components/PDF/PdfReports.vue";
export default {
  components: { PdfReports },
  name: "details-user-sales",
  data() {
    return {
      title: "",
      dialogPdf: false,
      data: [],
      totalserverData: 0,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN", "getSellerN"]),
    intervarThisYear() {
      const from = moment().startOf("year").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },

    headers() {
      const p = this.$route.params.query;
      const type = p.split("_")[0];
      if (type == "firstpayments") {
        return [
          { text: "Type", value: "typef", sortable: false },
          { text: "Sales", value: "salesf", sortable: false },
          { text: "Description", value: "descriptionf", sortable: false },
          {
            text: this.getpatientN + " name",
            value: "patient_name",
            sortable: false,
          },
          { text: "Amount $", value: "amountf", sortable: false, align: "end" },
          { text: "Payment date", value: "paymentDate", sortable: false },

          //{ text: "", value: "data-table-expand", sortable: false },
        ];
      }

      return [
        { text: "Type", value: "type", sortable: false },
        { text: "Sales", value: "sales", sortable: false },
        { text: "Description", value: "description", sortable: false },
        {
          text: this.getpatientN + " name",
          value: "patient_name",
          sortable: false,
        },
        { text: "Amount $", value: "amount", sortable: false, align: "end" },

        { text: "Payment date", value: "paymentDate", sortable: false },
        //{ text: "", value: "data-table-expand", sortable: false },
      ];
    },

    getEmployeeName() {
      const item = this.data[0];
      if (item == null || item == undefined) {
        return "-";
      }
      const p = this.$route.params.query;
      const type = p.split("_")[0];
      if (type == "firstpayments") {
        return item.seller_name;
      }

      if (item.employee == null) {
        return "-";
      }
      return item.employee.fullname;
    },
  },
  methods: {
    backreport() {
      this.$router.push("/reports");
    },
    getSales(item) {
      if (item.procedurename) {
        return item.procedurename;
      }
      if (item.products) {
        return item.products[0].description;
      }

      return "-";
    },
    getSalesf(item) {
      if (item.procedurename) {
        return item.procedurename;
      }
      if (item.sale_products) {
        return item.sale_products[0].description;
      }

      return "-";
    },
    getData() {
      const p = this.$route.params.query;
      const type = p.split("_")[0];
      const employeeUuid = p.split("_")[1];
      let body;
      this.loadingData = true;
      switch (type) {
        case "total":
          body = {
            employeeUuid: employeeUuid,
            range: {
              limit: 100,
              offset: 0,
            },
            status: "CONFIRMED",
            dateRange: {
              date1: this.intervarThisYear.date1,
              date2: this.intervarThisYear.date2,
            },
          };

          getAPI.post("/payment/filterList", body).then((res) => {
            this.data = res.data.result;
            this.totalserverData = res.data.count;
            this.loadingData = false;
          });
          break;
        case "firstpayments":
          body = {
            sellerUuid: employeeUuid,
            range: {
              limit: 100,
              offset: 0,
            },
            dateRange: {
              date1: this.intervarThisYear.date1,
              date2: this.intervarThisYear.date2,
            },
          };

          getAPI.post("/sprocedures/getFirstPayments", body).then((res) => {
            this.data = res.data.result;
            this.totalserverData = res.data.count;
            this.loadingData = false;
          });
          break;
        default:
          //
          break;
      }
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YYYY, h:mm a");
    },

    getPatientName(item) {
      if (item.procedure == null) {
        return "-";
      }
      if (item.procedure.patient == null) {
        return "-";
      }
      return item.procedure.patient.fullname;
    },

    getType(item) {
      if (item.payment == null || item.payment == undefined) {
        return "-";
      }
      return item.payment.type;
    },
    typesShow(type) {
      if (type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      return type;
    },
    getAmount(item) {
      if (item.payment == null || item.payment == undefined) {
        return 0;
      }
      return item.payment.amount;
    },
  },
  mounted() {
    const p = this.$route.params.query;
    const type = p.split("_")[0];
    if (type == "total") {
      this.title = "Total-Sales";
    } else {
      this.title = "First-Payments";
    }
    this.getData();
  },
};
</script>
<style lang=""></style>
