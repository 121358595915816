<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-dialog
      v-model="dialogPdf"
      persistent
      :overlay="false"
      max-width="820px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="ml-1"
          :disabled="dataTable.length == 0"
          color="primary"
          small
          >Generate PDF</v-btn
        >
      </template>
      <v-card>
        <v-card-title primary-title>
          PDF <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                large
                icon
                @click="generateReport"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" large icon @click="cancelGenerate"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </v-card-title>
        <div
          id="pdf"
          :style="{
            width: '800px !important',
            padding: '10px 10px 10px 5px !important',
            'text-align': 'center',
          }"
        >
          <v-divider></v-divider>
          <h3>{{ `${title}:  ${employee}` }}</h3>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Type</th>
                  <th class="text-left">Sales</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Patient name</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Payment date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dataTable" :key="item.name">
                  <td class="text-left">{{ typesShow(getType(item)) }}</td>
                  <td class="text-left">{{ getSales(item) }}</td>
                  <td class="text-left">
                    {{ getDetails(item) }}
                  </td>
                  <td class="text-left">
                    {{ getPatientName(item) }}
                  </td>
                  <td class="text-left">
                    {{ getAmount(item) | currency }}
                  </td>
                  <td class="text-left">{{ prettyDate(item.createAt) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
export default {
  name: "pdf-reports",
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "",
    },
    employee: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      dialogPdf: false,
    };
  },
  methods: {
    getType(item) {
      if (item.type) {
        return item.type;
      }
      if (item.payment == null || item.payment == undefined) {
        return "-";
      }
      return item.payment.type;
    },
    getSales(item) {
      if (item.procedureName) {
        return item.procedureName;
      }
      if (item.accesories) {
        return item.accesories[0].description;
      }
      if (item.procedurename) {
        return item.procedurename;
      }
      if (item.products) {
        return item.products[0].description;
      }

      return "-";
    },
    typesShow(type) {
      if (type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      return type;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YYYY, h:mm a");
    },
    generateReport() {
      const ti = "Sales-Sumary-" + this.title + ".pdf";
      window.html2canvas = html2canvas;
      let doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [1200, 792],
      });
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save(ti);
        },
      });
    },
    getPatientName(item) {
      if (item.patient) {
        return item.patient;
      }
      return item.patient_name ? item.patient_name : "-";
    },

    getDetails(item) {
      if (item.details) {
        return item.details;
      }
      return item.payment.details ? item.payment.details : "-";
    },

    getAmount(item) {
      if (item.amount) {
        return item.amount;
      }
      if (item.payment == null || item.payment == undefined) {
        return 0;
      }
      return item.payment.amount;
    },

    cancelGenerate() {
      this.dialogPdf = false;
    },
  },
};
</script>
<style lang=""></style>
