var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"820px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"disabled":_vm.dataTable.length == 0,"color":"primary","small":""}},on),[_vm._v("Generate PDF")])]}}]),model:{value:(_vm.dialogPdf),callback:function ($$v) {_vm.dialogPdf=$$v},expression:"dialogPdf"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" PDF "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","large":"","icon":""},on:{"click":_vm.generateReport}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Download PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","large":"","icon":""},on:{"click":_vm.cancelGenerate}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cancel")])])],1),_c('div',{style:({
          width: '800px !important',
          padding: '10px 10px 10px 5px !important',
          'text-align': 'center',
        }),attrs:{"id":"pdf"}},[_c('v-divider'),_c('h3',[_vm._v(_vm._s((_vm.title + ": " + _vm.employee)))]),_c('v-divider'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Sales")]),_c('th',{staticClass:"text-left"},[_vm._v("Description")]),_c('th',{staticClass:"text-left"},[_vm._v("Patient name")]),_c('th',{staticClass:"text-left"},[_vm._v("Amount")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment date")])])]),_c('tbody',_vm._l((_vm.dataTable),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.typesShow(_vm.getType(item))))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.getSales(item)))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getDetails(item))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getPatientName(item))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getAmount(item)))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.prettyDate(item.createAt)))])])}),0)]},proxy:true}])}),_c('v-divider')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }